import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import toolboxLogos from 'components/Page/Toolbox/data/toolboxLogos';
import toolboxSections from 'components/Page/Toolbox/data/toolboxSections';
import toolboxCardSections from 'components/Page/Toolbox/data/toolboxCardSections';
import ContentfulLayout from 'components/Contentful/ContentfulLayout';
import ImageCardSliderSection from 'components/Sections/ImageCardSections/ImageCardSliderSection';
import DownloadsResourcesSection from 'components/Page/Downloads/ResourcesSection';
import BannerAd from 'components/Page/Toolbox/BannerAd';
import useToolboxContentHub from 'hooks/useToolboxContentHub';

const Toolbox = ({ data }) => {
  const {
    mostPopularPosts,
    bannerAds,
    seo: seoContent,
    contentfulImage: backgroundImage,
  } = data.contentfulContentHub;

  const header = data.componentCollection.components.find(
    (component) => component.__typename === 'ContentfulHeader',
  );
  const hero = data.componentCollection.components.find(
    (component) => component.__typename === 'ContentfulLayout',
  );
  const toolboxContentHub = useToolboxContentHub();
  const { articlesBlackLogo, podcastBlackLogo, webinarBlackLogo } = toolboxLogos();
  const sections = toolboxSections();
  const cardSections = toolboxCardSections({ ...data, ...toolboxContentHub });

  return (
    <Container>
      <ToolboxLayout
        backgroundColor="#17191c"
        contentfulSeo={seoContent}
        header={header}
        layout="toolbox"
      >
        <ContentfulLayout {...hero} className="toolbox-hero" />
        <ImageCardSliderSection
          title="Most Popular"
          titleIcon="icon-popular"
          sectionsData={mostPopularPosts}
          hubType="Toolbox"
        />
        {cardSections.map((section, index) => {
          return (
            <React.Fragment key={index}>
              {index % 3 === 0 && bannerAds.length - 1 >= index / 3 && (
                <BannerAd bannerContent={bannerAds[index / 3]} />
              )}
              {section.data.length > 0 && (
                <ImageCardSliderSection
                  key={index}
                  title={section.category}
                  titleIcon={sections[section.category]?.src_black}
                  sectionsData={
                    section.ad && section.data.length >= 10
                      ? section.data.slice(0, 9)
                      : section.data
                  }
                  sectionsAd={section.ad}
                  hubType="Toolbox"
                  viewAll={sections[section.category]?.viewAll}
                />
              )}
              {section.category === 'Tools' && (
                <DownloadsResourcesSection
                  id="download"
                  templatePosts={toolboxContentHub.allTemplates?.nodes}
                  salaryPosts={toolboxContentHub.allSalary?.nodes}
                  licensingPosts={toolboxContentHub.allLicensing?.nodes}
                />
              )}
            </React.Fragment>
          );
        })}
      </ToolboxLayout>
    </Container>
  );
};

export default Toolbox;

export const ToolboxPage = graphql`
  query ToolboxPageQuery {
    contentfulContentHub(internalName: { eq: "Toolbox" }) {
      ...ContentHub
    }
    componentCollection: contentfulComponentCollection(
      contentful_id: { eq: "7ryijua6ajVUCJQwsCQeDF" }
    ) {
      ... on ContentfulComponentCollection {
        components {
          ...Header
          ... on ContentfulLayout {
            __typename
            componentMap
            stylesEntries: styles {
              ...Styles
            }
            section: sectionContent {
              ...Section
            }
            richText {
              ...RichText
            }
            template {
              ...Section
            }
          }
        }
      }
    }
  }
`;

const Container = styled.div`
  .main-container {
    padding: 0;
  }

  .toolbox-hero .section-container-row {
    padding: 250px 28px 75px 28px !important;

    @media (max-width: 1199px) {
      padding: 180px 28px 75px 28px !important;
    }

    @media (max-width: 767px) {
      padding: 150px 28px 50px 28px !important;
    }

    .subtitle {
      max-width: 100%;
    }
  }
`;
